import React, { useState } from "react"

const useMenu = () => {
  const [isShowing, setIsShowing] = useState(false)
  const reload = () => window.location.reload()

  function toggle() {
    setIsShowing(!isShowing)

    if (typeof document === "undefined") return null
    /* 
    if (isShowing && document.body?.clientWidth <= 1024) {
      document.body.style.overflow = "visible"
    } else {
      document.body.style.overflow = "hidden"
    } */
  }

  return { setIsShowing, isShowing, toggle, reload }
}

export default useMenu
