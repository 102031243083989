import { useEffect } from "react"

const useScrollPosition = callback => {
  useEffect(() => {
    const listener = () => {
      callback({ x: window.pageXOffset, y: window.pageYOffset })
    }
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", listener)
      return () => window.removeEventListener("scroll", listener)
    }
  }, [])

  return null
}

export default useScrollPosition
