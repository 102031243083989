import styled from "styled-components"
import footer from "../../assets/images/footer.png"

export const Wrapper = styled.div`
  width: 100%;
  background-image: url(${footer});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 380px;
  @media only screen and (min-width: 1024px) {
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
  }
`
export const InnerWrapper = styled.div`
  width: 90%;
  margin: auto;
  min-height: 280px;
  background-color: #fff;
  padding: 1rem;
  @media only screen and (min-width: 576px) {
    width: 540px;
  }
  @media only screen and (min-width: 768px) {
    width: 720px;
    display: flex;
    padding: 1rem 2rem;
  }
  @media only screen and (min-width: 992px) {
    width: 960px;
    padding: 1rem 2.5rem;
  }
  @media only screen and (min-width: 1200px) {
    width: 1140px;
  }
  @media only screen and (min-width: 1440px) {
    width: 1220px;
  }
  h2 {
    color: #7b6244;
    font-size: 32px;
    font-weight: 700;
  }
  p {
    line-height: 32px;
    margin: 0;
  }
  span {
    font-weight: 700;
  }
`

export const Info = styled.div`
  width: 75%;
`

export const Contact = styled.div`
  width: 100%;
  border-bottom: 3px solid #3a686e;
  a {
    color: #3f4a59;
    text-decoration: none;
    display: block;
  }
  @media only screen and (min-width: 768px) {
    width: 20%;
    margin-left: auto;
    margin-top: auto;
    border-bottom: none;
  }
`

export const Text = styled.div`
  display: none;
  @media only screen and (min-width: 768px) {
    margin: 1rem 0;
    display: block;
    width: 100%;
    border-top: 3px solid #3a686e;
    p {
      margin: 1rem 0;
    }
  }
`

export const Social = styled.div`
  display: flex;
  svg {
    margin: 1rem 1rem 1rem 0;
  }
`
