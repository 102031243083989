import React from "react"
import ReactDOM from "react-dom"
import { LogoWrapper, MenuWrapper } from "./styles"
import MenuQuery from "./MenuQuery"
import ConditionalWrapper from "../../utils/ConditionalWrapper"
import LockWrapper from "../Header/LockWrapper"
import { Link } from "gatsby"

const Menu = React.forwardRef((props, ref) => {
  if (typeof document === "undefined") return null
  if (typeof window === "undefined") return null

  const pos = window.location.pathname

  return ReactDOM.createPortal(
    <MenuWrapper open={props.isShowing} ref={ref}>
      <LogoWrapper>
        {pos === "/" ? (
          <button type="button" onClick={() => props.setIsShowing(false)}>
            {logo}
          </button>
        ) : (
          <Link to="/">{logo}</Link>
        )}
      </LogoWrapper>
      <ConditionalWrapper
        condition={props.isShowing && document.body?.clientWidth <= 1023}
        wrapper={children => <LockWrapper>{children}</LockWrapper>}
      >
        {props.isShowing && <MenuQuery setIsShowing={props.setIsShowing} />}
      </ConditionalWrapper>
    </MenuWrapper>,
    document.body
  )
})

const logo = (
  <svg width="133.252" height="55.298" viewBox="0 0 113.252 34.298">
    <defs>
      <rect width="113.252" height="34.298" fill="#3f4a59" />
    </defs>
    <path
      d="M10.162,13.77,9.7,13.62c-1.239-.4-2.389-.752-3.408-1.062A4.387,4.387,0,0,1,3,8.1a5.46,5.46,0,0,1,1.629-4,5.3,5.3,0,0,1,7.731,0,5.461,5.461,0,0,1,1.63,4V9.892h2.562V8.1A7.93,7.93,0,0,0,8.5,0,7.93,7.93,0,0,0,.441,8.116,7.32,7.32,0,0,0,1.625,12.2C2.957,14.18,5,14.8,7.16,15.449l.662.2c1.172.391,2.311.742,3.358,1.06a4.646,4.646,0,0,1,3.255,4.723v2.781a5.657,5.657,0,0,1-1.762,4.174,5.727,5.727,0,0,1-8.348,0,5.657,5.657,0,0,1-1.762-4.174V20.131H0v4.085a8.059,8.059,0,0,0,2.432,5.979A8.163,8.163,0,0,0,8.5,32.626a8.164,8.164,0,0,0,6.068-2.432A8.059,8.059,0,0,0,17,24.216v-2.6A8.142,8.142,0,0,0,15.9,17.292c-1.412-2.118-3.515-2.8-5.742-3.522"
      transform="translate(0 -0.001)"
      fill="#3f4a59"
    />
    <path
      d="M88.547,0a8.163,8.163,0,0,0-8.411,8.367V31.831H82.7V20.69a7.774,7.774,0,0,0,5.936,2.4,8.088,8.088,0,0,0,8.323-8.323v-6.4A8.163,8.163,0,0,0,88.547,0m0,2.43A5.624,5.624,0,0,1,92.71,4.137a5.959,5.959,0,0,1,1.685,4.23v6.4a5.83,5.83,0,0,1-1.674,4.174,5.635,5.635,0,0,1-4.174,1.718A5.446,5.446,0,0,1,84.5,19.024a5.527,5.527,0,0,1-1.8-3.992V8.367a5.827,5.827,0,0,1,1.724-4.224A5.517,5.517,0,0,1,88.547,2.43"
      transform="translate(-60.271)"
      fill="#3f4a59"
    />
    <path
      d="M167.35,0a7.713,7.713,0,0,0-7.97,7.925V31.831h2.563V20.63h10.814v11.2h2.563V7.925A7.713,7.713,0,0,0,167.35,0m0,2.43A5.271,5.271,0,0,1,171.2,4a5.614,5.614,0,0,1,1.554,3.922V18.2H161.943V7.925a5.41,5.41,0,0,1,1.592-3.916A5.093,5.093,0,0,1,167.35,2.43"
      transform="translate(-119.872 0)"
      fill="#3f4a59"
    />
    <path
      d="M252.987,28.522a5.87,5.87,0,0,1-1.678,4.194,5.6,5.6,0,0,1-4.194,1.765,5.444,5.444,0,0,1-4.15-1.765,5.732,5.732,0,0,1-1.721-4.194V5.081h-1.633V28.522a7.217,7.217,0,0,0,2.119,5.341,7.9,7.9,0,0,0,10.771,0,7.218,7.218,0,0,0,2.119-5.341V5.081h-1.633Z"
      transform="translate(-180.214 -3.821)"
      fill="#3f4a59"
    />
    <path
      d="M325.471,1.875a7.318,7.318,0,0,0-5.386,2.119,7.219,7.219,0,0,0-2.119,5.341V32.777H319.6V9.336A5.9,5.9,0,0,1,321.321,5.1a5.563,5.563,0,0,1,4.15-1.722A5.731,5.731,0,0,1,329.664,5.1a6.049,6.049,0,0,1,1.677,4.238V32.777h1.633V9.336a7.219,7.219,0,0,0-2.119-5.341,7.318,7.318,0,0,0-5.386-2.119"
      transform="translate(-239.146 -1.41)"
      fill="#3f4a59"
    />
    <path
      d="M409.209,3.995a7.9,7.9,0,0,0-10.771,0,7.219,7.219,0,0,0-2.119,5.341V32.777h1.633V22.041H409.7V32.777h1.633V9.336a7.22,7.22,0,0,0-2.119-5.341M409.7,20.54H397.952V9.336A5.9,5.9,0,0,1,399.674,5.1a5.561,5.561,0,0,1,4.149-1.722A5.728,5.728,0,0,1,408.017,5.1,6.047,6.047,0,0,1,409.7,9.336Z"
      transform="translate(-298.076 -1.411)"
      fill="#3f4a59"
    />
  </svg>
)

export default Menu
