import React from "react"
import { Contact, Info, InnerWrapper, Social, Text, Wrapper } from "./styles"

const Footer = () => {
  return (
    <Wrapper id="kontakt">
      <InnerWrapper>
        <Info>
          <h2>Leia tee meieni</h2>
          <p>Meie moodne</p>
          <p>
            SPA ja SAUNA salong asub <span>Tartus, Riia 140</span>
          </p>
          <Text>
            <p>SPAUNA - MÕNUSA SELTSKONNA JA HEA TUJU MAALETOOJA. </p>
          </Text>
        </Info>
        <Contact>
          <a href="mailto:info@spauna.ee">info@spauna.ee</a>
          <a href="tel:+372  5146825">+372 51 468 25</a>

          <Social>
            <a href="https://www.facebook.com/spauna.ee/" target="blank">
              {facebook}
            </a>
          </Social>
        </Contact>
      </InnerWrapper>
    </Wrapper>
  )
}

const facebook = (
  <svg width="16.5" height="30" viewBox="0 0 16.5 30">
    <path
      d="M27,3H22.5A7.5,7.5,0,0,0,15,10.5V15H10.5v6H15V33h6V21h4.5L27,15H21V10.5A1.5,1.5,0,0,1,22.5,9H27Z"
      transform="translate(-10.5 -3)"
      fill="#3a686e"
    />
  </svg>
)

const instagram = (
  <svg width="31.518" height="31.51" viewBox="0 0 31.518 31.51">
    <path
      d="M15.757,9.914a8.079,8.079,0,1,0,8.079,8.079A8.066,8.066,0,0,0,15.757,9.914Zm0,13.331a5.252,5.252,0,1,1,5.252-5.252,5.262,5.262,0,0,1-5.252,5.252ZM26.051,9.584A1.884,1.884,0,1,1,24.166,7.7,1.88,1.88,0,0,1,26.051,9.584ZM31.4,11.5a9.325,9.325,0,0,0-2.545-6.6,9.387,9.387,0,0,0-6.6-2.545c-2.6-.148-10.4-.148-13,0a9.373,9.373,0,0,0-6.6,2.538,9.356,9.356,0,0,0-2.545,6.6c-.148,2.6-.148,10.4,0,13a9.325,9.325,0,0,0,2.545,6.6,9.4,9.4,0,0,0,6.6,2.545c2.6.148,10.4.148,13,0a9.325,9.325,0,0,0,6.6-2.545,9.387,9.387,0,0,0,2.545-6.6c.148-2.6.148-10.392,0-12.994ZM28.041,27.281a5.318,5.318,0,0,1-3,3c-2.074.823-7,.633-9.288.633s-7.221.183-9.288-.633a5.318,5.318,0,0,1-3-3c-.823-2.074-.633-7-.633-9.288s-.183-7.221.633-9.288a5.318,5.318,0,0,1,3-3c2.074-.823,7-.633,9.288-.633s7.221-.183,9.288.633a5.318,5.318,0,0,1,3,3c.823,2.074.633,7,.633,9.288S28.863,25.214,28.041,27.281Z"
      transform="translate(0.005 -2.238)"
      fill="#3a686e"
    />
  </svg>
)

export default Footer
