import styled, { createGlobalStyle, css } from "styled-components"

export const MenuWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  z-index: 5;
  right: 0;
  padding: 1rem 2rem;
  padding-top: calc(93px + 1rem);
  opacity: 0;
  transition: 0.35s all ease;
  pointer-events: none;
  user-select: none;

  ${({ open }) => {
    if (open) {
      return css`
        pointer-events: unset;
        user-select: unset;
        opacity: 1;
      `
    }
  }}
  @media only screen and (min-width: 1024px) {
    top: 0;
    height: 100vh;
    opacity: 1;
    width: 600px;
    right: -600px;
    padding-left: 150px;
    box-shadow: 0px 3px 35px rgba(0, 0, 0, 16%);
    svg {
      z-index: 10;
    }
    ${({ open }) => {
      if (open) {
        return css`
          right: 0;
        `
      }
    }}
`
export const MenuItemsWrapper = styled.div`
  position: relative;
  height: 100%;
  a,
  button {
    background-color: transparent;
    border: none;
    display: block;
    text-decoration: none;
    color: #3a686e;
    font-weight: 500;
    font-size: 30px;
    line-height: 41px;
    margin-bottom: 1.5rem;
  }
  svg {
    border-radius: 50%;
    position: absolute;
    bottom: 2rem;
    opacity: 0.15;
  }
  @media only screen and (min-width: 375px) and (min-height: 750px) {
    svg {
      bottom: 8rem;
    }
  }
  @media only screen and (max-width: 374px) {
    a,
    button {
      font-size: 24px;
      margin-bottom: 1rem;
      line-height: 32px;
    }
  }
  @media only screen and (min-width: 375px) and (max-height: 680px) {
    a,
    button {
      margin-bottom: 1rem;
      font-size: 29px;
      line-height: 40px;
    }
    svg {
      bottom: 4.5rem;
    }
  }
  @media only screen and (min-width: 1024px) {
    padding-top: 100px;
    a,
    button {
      font-size: 47px;
      line-height: 65px;
    }
    svg {
      height: 300px;
      width: 300px;
      bottom: 2rem;
    }
  }
  @media only screen and (min-width: 1024px) and (max-height: 1000px) {
    padding-top: 50px;
    svg {
      bottom: 0;
    }
  }

  @media only screen and (min-width: 1024px) and (max-height: 900px) {
    padding-top: 50px;
    a,
    button {
      font-size: 42px;
      line-height: 55px;
    }
  }
  @media only screen and (min-width: 1024px) and (max-height: 900px) {
    svg {
      height: 250px;
      width: 250px;
      bottom: 0;
    }
  }
  @media only screen and (min-width: 1024px) and (max-height: 800px) {
    padding-top: 25px;
    a,
    button {
      font-size: 38px;
      line-height: 43px;
    }
  }
  @media only screen and (min-width: 1024px) and (max-height: 710px) {
    a,
    button {
      font-size: 36px;
      line-height: 40px;
    }
    svg {
      width: 230px;
      height: 230px;
    }
  }
  @media only screen and (min-width: 1024px) and (max-height: 680px) {
    svg {
      display: none;
    }
  }
`
export const LogoWrapper = styled.div`
  display: none;
  @media only screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      &:active {
        outline: none;
      }
      &:focus {
        outline: none;
      }
    }
  }
`

export const MenuText = styled.div`
  position: absolute;
  bottom: 3rem;
  left: 1rem;
  max-width: 260px;
  h2 {
    color: #7b6244;
    font-size: 25px;
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 0.75rem;
  }
  p {
    font-size: 13px;
    margin-bottom: 0;
    line-height: 18px;
  }
  span {
    font-weight: 700;
    font-size: 13px;
  }
  @media only screen and (min-width: 375px) and (min-height: 750px) {
    bottom: 10.5rem;
  }
  @media only screen and (min-width: 1024px) {
    bottom: 8rem;
    left: 1.75rem;
    max-width: unset;
    h2 {
      font-size: 33px;
      line-height: 25px;
      margin-bottom: 1rem;
    }
    p {
      font-size: 17px;
      line-height: 24px;
      max-width: 270px;
    }
    span {
      font-size: 17px;
    }
  }
  @media only screen and (min-width: 1024px) and (max-height: 1000px) {
    bottom: 6rem;
  }
  @media only screen and (min-width: 1024px) and (max-height: 900px) {
    bottom: 5rem;
    left: 1rem;
    max-width: 260px;
    h2 {
      font-size: 26px;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 15px;
    }
    span {
      font-size: 15px;
    }
  }
  @media only screen and (min-width: 375px) and (max-height: 680px) {
    bottom: 7rem;
  }
  @media only screen and (min-width: 1024px) and (max-height: 680px) {
    left: 0;
    bottom: 3.5rem;
  }
  @media only screen and (min-width: 1024px) and (max-height: 640px) {
    bottom: 1rem;
  }
  @media only screen and (min-width: 1024px) and (max-height: 570px) {
    bottom: 0rem;
  }
`
