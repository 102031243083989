import styled, { css } from "styled-components"

export const Wrapper = styled.div`
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: transparent;
  z-index: 6;
  transition: 0.25s ease;
  ${props =>
    props.background &&
    css`
      background-color: #fff;
      transition: 0.25s ease;
    `}
  @media only screen and (min-width: 1024px) {
    background-color: #fff;
    z-index: 5;
  }
`

export const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  p {
    display: none;
  }
  a {
    margin: auto;
    svg {
      width: 105px;
      overflow: visible;
    }
  }
  button {
    cursor: pointer;
    background: transparent;
    border: none;
    margin: auto;
    padding: 0;
    svg {
      width: 105px;
    }
    &:active {
      outline: none;
    }
    &:focus {
      outline: none;
    }
  }
  @media only screen and (min-width: 1024px) {
    padding: 0 1rem;
    a {
      margin: unset;
    }
    button {
      margin: unset;
      svg {
        width: 120px;
      }
    }
    svg {
      width: 120px;
    }
    p {
      display: block;
      margin: 0;
      margin-left: 2rem;
      font-size: 17px;
    }
  }
  @media only screen and (min-width: 1200px) {
    max-width: 1140px;
    margin: auto;
  }
  @media only screen and (min-width: 1400px) {
    max-width: 1220px;
  }
`

export const MaxHeight = styled.div`
  height: 100%;
`

export const MenuToggler = styled.div`
  position: absolute;
  right: 0;
  cursor: pointer;
  ${({ cancelClick }) => cancelClick && `pointer-events: none !important;`}
`
