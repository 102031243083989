import React, { useRef, useEffect } from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { MaxHeight } from "./styles"

const LockWrapper = ({ children }) => {
  const ref = useRef()
  useEffect(() => {
    const current = ref.current
    if (current) {
      disableBodyScroll(current)
    }
    return () => enableBodyScroll(current)
  }, [ref])
  return <MaxHeight ref={ref}>{children}</MaxHeight>
}

export default LockWrapper
